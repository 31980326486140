import Modal from 'react-modal';
import styled from 'styled-components';
import { ModalFooter, ModalTemplate } from 'components/Modal';
import Button from 'components/Button';

const Description = styled.div`
  padding: 10px;
  background: #f2f2f2;
  border: 1px solid #dedede;
  border-radius: 4px;
`;

export default function ModalNotes({
  modalConfig,
  isOpen,
  handleClose,
  modalType,

  title,
  description,
}) {
  return (
    <Modal
      {...modalConfig}
      isOpen={isOpen}
      contentLabel={modalType}
      onRequestClose={handleClose}
    >
      <ModalTemplate title={title} handleClose={handleClose}>
        <Description dangerouslySetInnerHTML={{ __html: description }} />

        <ModalFooter>
          <span className="h-flex__cell--grow" />
          <Button color="white" onClick={handleClose}>
            Fechar
          </Button>
        </ModalFooter>
      </ModalTemplate>
    </Modal>
  );
}
