import FieldGroundRange from 'components/FieldGroundRange';
import { FieldBool, FieldInterval, Input } from 'components/Form';
import * as Filters from 'components/PropertyFilters';
import FieldMultiCharacteristics from 'containers/FieldMultiCharacteristics';
import FieldMultiCondoCharacteristics from 'containers/FieldMultiCondoCharacteristics';
import Rooms from 'containers/ModalRoot/ModalPropertySearch/components/Rooms';
import { useModalPropertySearch } from 'containers/ModalRoot/ModalPropertySearch/context';
import useFormValue from 'hooks/useFormValue';
import useReduxForm from 'hooks/useReduxForm';
import {
  currentTypeSelector,
  isSellTransaction,
  isTypeGroundSelector,
  receiveTypes,
  typeOrSubtypeIdSelector,
} from 'modules/modalPropertySearch';
import { Col, Row } from 'react-flexbox-grid';
import { useDispatch, useSelector } from 'react-redux';
import Nolocation from '../Nolocation';
import { closeModal } from 'modules/modal';
import { Field } from 'redux-form';
import React from 'react';
import { debounce } from 'debounce';
import { MdInfo } from 'react-icons/md';

// import { Wrapper } from './styles';

function MainFields() {
  const { handleUpdateCount } = useModalPropertySearch();
  const condomoniumId = useFormValue('condominium_id');
  const transaction = useFormValue('transaction');
  const isSeasonAvailable = useFormValue('is_season_available');

  const { change } = useReduxForm();
  const dispatch = useDispatch();
  const isSell = useSelector(isSellTransaction);
  const typeOrSubtypeId = useSelector(typeOrSubtypeIdSelector);
  const currentType = useSelector(currentTypeSelector);
  const isGround = useSelector(isTypeGroundSelector);

  const handleInputChange = debounce((event, newValue) => {
    handleUpdateCount();
  }, 500);

  const clearFields = () => {
    dispatch(change('by_condos_characteristic_id', null));
    dispatch(change('by_characteristic_id', null));
    dispatch(change('situation_id', null));
    dispatch(change('is_exchangeable', null));
    dispatch(change('is_corner', null));
    dispatch(change('has_furniture', null));
    dispatch(change('price', null));
    dispatch(change('by_information_ilike', null));
  };

  const onLoadTypeAndSubtype = (types) => {
    dispatch(receiveTypes(types));
  };

  const handleChangeTransaction = (transaction) => {
    clearFields();
    dispatch(change('is_season_available', null));
    dispatch(change('available_lower_equals', null));
    dispatch(change('available_greater_equals', null));
    if (transaction === 1) {
      dispatch(change('price.with_total_price', false));
    } else {
      dispatch(change('price.with_total_price', true));
    }
    handleUpdateCount();
  };

  const handleChangeTypeAndSubtype = (typeOrSubtype, ...props) => {
    const typeName = typeOrSubtype?.type?.title || typeOrSubtype?.title;
    dispatch(change('type_name', typeName));
    dispatch(change('by_area', {}));
    dispatch(change('by_room', {}));
    clearFields();
    handleUpdateCount();
  };

  const handleEsc = (e) => {
    if (e.keyCode === 27 || e.keyCode === '27') {
      dispatch(closeModal());
    }
  };

  return (
    <Row>
      <Col xs={3}>
        <Filters.TypeAndSubtype
          onChange={handleChangeTypeAndSubtype}
          onLoad={onLoadTypeAndSubtype}
          onInputKeyDown={handleEsc}
        />
        <Filters.Transaction canRemoveSelf onChange={handleChangeTransaction} />
        {!condomoniumId ? (
          <Filters.NewLocation onChange={handleUpdateCount} />
        ) : (
          <Nolocation />
        )}
        <Filters.Profile onChange={handleUpdateCount} />
      </Col>
      <Col xs={3}>
        <Rooms onChange={handleUpdateCount} />
        <Filters.Situations
          typeOrSubtypeId={typeOrSubtypeId}
          onChange={handleUpdateCount}
        />
        <Filters.NearSea onChange={handleUpdateCount} />
      </Col>

      <Col xs={3}>
        <Filters.PriceFieldRent
          onBlur={handleUpdateCount}
          typeSaleOrRent={transaction}
        />
        <FieldGroundRange type={currentType} onBlur={handleUpdateCount} />
        {isGround ? (
          <FieldBool
            onChange={handleUpdateCount}
            canRemoveSelf
            name="is_corner"
            label="Esquina"
          />
        ) : (
          <FieldBool
            onChange={handleUpdateCount}
            canRemoveSelf
            name="has_furniture"
            label="Mobiliado"
          />
        )}
        <FieldBool
          canRemoveSelf
          buttonTemplate
          name="is_exchangeable"
          label="Aceita permuta?"
          onChange={handleUpdateCount}
        />
        {isGround ? (
          <Field
            label="Unidade"
            name="by_information_ilike.lot_number"
            component={Input}
            onChange={handleInputChange}
          />
        ) : null}
      </Col>

      <Col xs={3}>
        {isSell ? (
          <FieldBool
            onChange={handleUpdateCount}
            canRemoveSelf
            buttonTemplate
            name="is_financeable"
            label="Aceita financiamento?"
          />
        ) : (
          <FieldBool
            onChange={handleUpdateCount}
            canRemoveSelf
            buttonTemplate
            name="is_season_available"
            label="Temporada?"
          />
        )}
        {isSell && (
          <FieldBool
            onChange={handleUpdateCount}
            canRemoveSelf
            buttonTemplate
            name="is_financeable_mcmv"
            label="Minha casa minha vida?"
          />
        )}
        <FieldMultiCharacteristics
          onChange={handleUpdateCount}
          typeId={currentType?.type_id}
        />
        <FieldMultiCondoCharacteristics onChange={handleUpdateCount} />
        {isGround ? (
          <Field
            label="Descrição do lote"
            name="by_information_ilike.lot_description"
            component={Input}
            onChange={handleInputChange}
          />
        ) : null}
        {isSeasonAvailable ? (
          <FieldInterval
            onChange={handleUpdateCount}
            title={
              <>
                Datas disponíveis{' '}
                <MdInfo data-tip="Preencher os 2 campos para realizar a pesquisa" />
              </>
            }
            nameMax="available_lower_equals"
            nameMin="available_greater_equals"
          />
        ) : null}
      </Col>
    </Row>
  );
}

export default MainFields;
