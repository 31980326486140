export const COUNTRY_ID = 'dfb22ce9-8112-4e60-bc92-ecdba85b1ddc';

export const PAYMENT_METHODS = {
  CREDIT_CARD: '2',
  BOLETO: '1',
  BONUS: '3',
  PIX: '4',
};

export const STATUS_CRECI = {
  NOT_VERIFIED: 1,
  WAITING: 2,
  VERIFIED: 3,
  BLOCKED_BY_SYSTEM: 4,
  BLOCKED_BY_SUPPORT: 5,
};

export const STATUS_PROPERTY = {
  AVAILABLE: 1,
  SOLD: 2,
  RENTED: 3,
  EXCLUDED: 4,
};

export const LAZY_PASSWORDS = [
  '000000',
  '111111',
  '222222',
  '333333',
  '444444',
  '555555',
  '666666',
  '777777',
  '888888',
  '999999',
  '123123',
  '321321',
  '123456',
  '1234567',
  '12345678',
  '123456789',
  'qwerty',
  '121212',
  '123abc',
  'abc123',
  'a1b2c3',
  'a1b2c3d4',
  'a1b2c3d4e5',
];

export const AVALIABLE_FONTS = [
  { family: 'Arial' },
  { family: 'Dosis' },
  { family: 'Quicksand' },
  { family: 'Exo 2' },
  { family: 'Fjalla One' },
  { family: 'Raleway' },
  { family: 'Lato' },
  { family: 'Open Sans' },
  { family: 'Roboto' },
  { family: 'Roboto Mono' },
  { family: 'Poppins' },
  { family: 'Nunito' },
  { family: 'Montserrat' },
  { family: 'Playfair Display' },
  { family: 'Libre Baskerville' },
  { family: 'Urbanist' },
];

export const NOTIFICATION_TYPES = {
  MODAL: 'modal',
  LINK: 'link',
  LINK_BLANK: 'link_blank',
  CONFIRM: 'confirm',
  ALERT: 'alert',
};

export const NOTIFICATION_STATUS = {
  NEW: 1,
  RECEIVED: 2,
  READED: 3,
};
