import Button from 'components/Button';
import ListOptions from 'components/ListOptions';
import { openConfirmation } from 'containers/ModalConfirmation/module';
import { openModalPleno } from 'modules/modal';
import { useDispatch } from 'react-redux';
import Alert from 'react-s-alert';
import * as plenoService from 'services/integrations/pleno';
import logo from './pleno-logo-typo.png';
import { receiveUser } from 'modules/login';
import { useCurrentUser } from 'hooks/useCurrentUser';

function PlenoIntegration() {
  const dispatch = useDispatch();

  const currentUser = useCurrentUser();

  const isConnected = currentUser.real_estate.auths.plenoimob;

  const updatePlenoImob = (newValue) => {
    dispatch(
      receiveUser({
        ...currentUser,
        real_estate: {
          ...currentUser.real_estate,
          auths: {
            ...currentUser.real_estate.auths,
            plenoimob: newValue,
          },
        },
      })
    );
  };

  const connect = async () => {
    dispatch(
      openModalPleno({
        afterClose: () => {
          updatePlenoImob(true);
        },
      })
    );
  };

  const disconnect = async () => {
    dispatch(
      openConfirmation({
        request: () => plenoService.disconnect(),
        title: 'Deseja realmente desconectar?',
        submitButtonText: 'Desconectar',
        onSuccess: () => {
          Alert.success('Pleno imob desconectado');
          updatePlenoImob(false);
        },
      })
    );
  };

  return (
    <ListOptions.item
      image={logo}
      imageStyle={{
        width: 42,
      }}
      title="Pleno Imob"
      text="Sistema externo para gestão imobiliária"
      renderOptions={() => {
        if (!isConnected) {
          return (
            <Button onClick={connect} color="success">
              Conectar
            </Button>
          );
        }

        return (
          <Button color="danger" onClick={disconnect}>
            Desconectar
          </Button>
        );
      }}
    />
  );
}

export default PlenoIntegration;
