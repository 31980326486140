import Loading from 'components/Loading';
import { LocationProvider } from 'containers/LocationAsyncFields/context';
import { withFormConfirmation } from 'HOC/withFormConfirmation';
import withProvider from 'HOC/withProvider';
import FooterActions from 'pages/CondoStore/components/FooterActions';
import { finishResponse } from 'pages/CondoStore/helpers';
import { withId } from 'pages/CondoStore/HOC/withId';
import { usePath } from 'pages/CondoStore/hooks/usePath';
import { useCallback, useEffect } from 'react';
import { useParams } from 'react-router';
import { compose } from 'redux';
import { reduxForm } from 'redux-form';
import * as condosService from 'services/condos';
import CondoCharacteristics from './sections/CondoCharacteristics';
import Description from './sections/Description';
import PrivateDescription from './sections/PrivateDescription';
import Establishments from './sections/Establishments';
import InformationsSection from './sections/Informations';
import Location from './sections/Location';
import Video from './sections/Video';
import { transformData, transformResponse } from './transformers';
import validate from './validate';

function Informations({
  handleSubmit,
  onSubmit,
  submitting,
  initialize,
  pathname,
}) {
  const { id } = useParams();

  const { setPath } = usePath();

  useEffect(() => {
    setPath('/informations');
  }, []);

  const initializeForm = useCallback(async () => {
    const { data } = await condosService.getOne(id, {
      include: 'establishments,condos_characteristics',
    });

    const newValues = transformResponse(data);

    initialize(newValues);
  }, [initialize, id]);

  useEffect(() => {
    if (id) initializeForm();
  }, [id]);

  return (
    <form onSubmit={handleSubmit}>
      <Loading isVisible={submitting} isFullComponent />

      <InformationsSection />
      <Location />
      <CondoCharacteristics />
      <Establishments />
      <Video />
      <Description />
      <PrivateDescription />

      <FooterActions handleSubmit={handleSubmit} onSubmit={onSubmit} />
    </form>
  );
}

export default compose(
  withId,
  reduxForm({
    form: 'CondoInformations',
    validate,
    onSubmit: (values, dispatch, props) => {
      const newValues = transformData(values);

      // Se não tiver ID
      if (!props?.id) {
        return condosService.create(newValues);
      }

      return condosService
        .update(newValues)
        .then(finishResponse(newValues, dispatch, props));
    },
    onSubmitSuccess: (response, dispatch, p) => {
      p.initialize(transformResponse(response?.data));
      p.next('images', response?.data.id);

      if (!p.isEditting) {
        p.enableAll();
        p.setId(response?.data.id);
      }
    },
  }),
  withProvider(LocationProvider),
  withFormConfirmation()
)(Informations);
