// import styles from './styles'

import { Aside, Content, Wrapper } from 'pages/PropertiesSearch/styles';
import CurrentSearch from 'pages/PropertiesSearch/components/CurrentSearch';
import Loading from 'components/Loading';
import Nodata from 'components/Nodata';
import house from 'assets/img/noData/house.svg';
import Button from 'components/Button';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { useHashChange } from 'hooks/useHashChange';
import {
  clearSearch,
  getProperties,
  propertiesSelector,
  setNetwork,
  setTypeList,
} from 'modules/propertySearch';
import { useQuery } from 'hooks/useQuery';
import { useEffect } from 'react';
import { openModalPropertySearch } from 'modules/modal';
import FixedBar from './components/FixedBar';
import Properties from './components/Properties';
import { createSelector } from 'reselect';

export const selectedPropertiesSelector = (state) =>
  state?.selecteds?.properties_search2;

export const getPropertiesSelecteds = createSelector(
  [propertiesSelector, selectedPropertiesSelector],
  (properties, selecteds) => {
    if (!properties.data || !selecteds) return properties.data;

    return properties.data.map((property) => {
      if (selecteds.indexOf(property.id) !== -1) {
        return { ...property, isChecked: true };
      }
      return property;
    });
  }
);

const hasPropertiesCount = (count) => {
  return (
    count?.self?.count > 0 ||
    count?.guru?.count > 0 ||
    count?.orulo?.count > 0 ||
    count?.dwv?.count > 0
  );
};

export default function FeaturedPropertyAdd() {
  const { id } = useParams();

  const dispatch = useDispatch();
  const history = useHistory();

  const isOpenModal = useHashChange('#openSearch');
  const properties = useSelector(getPropertiesSelecteds);
  const { meta } = useSelector(propertiesSelector);
  const params = useQuery();

  const handleClearSearch = () => {
    dispatch(clearSearch(history));
  };

  const handleSearch = () => {
    dispatch(
      getProperties({
        ...params,
        type_list: params.type_list,
        count: 'deals',
      })
    );
  };

  const hasProperties =
    properties.length > 0 || hasPropertiesCount(params?.filter?.count);

  useEffect(() => {
    if (params?.filter) {
      handleSearch();
    }
  }, [params]);

  useEffect(() => {
    if (isOpenModal) {
      dispatch(openModalPropertySearch());
    }
  }, [isOpenModal]);

  useEffect(() => {
    if (params.type_list) {
      dispatch(setTypeList(params.type_list));
    } else {
      dispatch(setTypeList('card'));
    }

    if (params?.filter?.on_network) {
      dispatch(setNetwork(params?.filter?.on_network[0]));
    }
  }, [params]);

  return (
    <Wrapper>
      <Aside className="js-aside">
        <CurrentSearch
          paramsModal={{
            isPortals: true,
          }}
          query={params}
          redirectTo={`/featured/${id}/property-add`}
        />
      </Aside>
      <Content>
        <Loading isFullScreen isVisible={meta.isFetching} />
        {!meta.isFetching && params?.filter && !hasProperties ? (
          <Nodata
            image={house}
            title="Nenhum imóvel encontrado"
            text="Clique no botão limpar para"
            style={{ marginTop: '45px' }}
          >
            <Button
              color="danger"
              onClick={handleClearSearch}
              className="h-margin-top--15"
            >
              Limpar pesquisa
            </Button>
          </Nodata>
        ) : hasProperties ? (
          <Properties
            handleSearch={handleSearch}
            data={properties}
            params={params}
            meta={meta}
            fixedBar={FixedBar}
            barProps={{}}
          />
        ) : (
          <Nodata
            image={house}
            title="Pesquise primeiro"
            text="Clique no botão limpar para"
            style={{ marginTop: '45px' }}
          />
        )}
      </Content>
    </Wrapper>
  );
}
