import { Financial } from 'features/Financial';
import * as S from './styles';
import { useDispatch } from 'react-redux';
import Button from 'components/Button';
import { MdDone } from 'react-icons/md';
import { financialStartFreeTrial } from '../../../../modules/statusGuard';

export function RequestAccessGranted() {
  const dispatch = useDispatch();

  return (
    <Financial.Content>
      <S.Box>
        <S.Wrapper>
          <S.BoxLeft>
            <S.Title>Seu Módulo Financeiro está liberado!</S.Title>
            <S.Text>
              Boa notícia! Seu acesso ao Módulo Financeiro foi liberado e você
              já pode começar o teste gratuito de 7 dias.
              <br />
              <br />
              Aproveite esse período para explorar todos os recursos e verificar
              como a ferramenta pode otimizar suas rotinas de contas a pagar e a
              receber.
              <br />
              <br />
              Pedimos que você inicie o quanto antes (nos próximos 3 dias). Caso
              contrário, precisaremos repassar a vez para outro cliente
              interessado, e você retornará à lista de espera.
            </S.Text>
            <Button
              color="success"
              onClick={() => {
                dispatch(financialStartFreeTrial());
              }}
            >
              Começar meus 7 dias de teste agora
            </Button>
          </S.BoxLeft>
          <S.BoxRight>
            <S.Icon>
              <MdDone fill="#D3DCEB" />
            </S.Icon>
          </S.BoxRight>
        </S.Wrapper>
      </S.Box>
    </Financial.Content>
  );
}
