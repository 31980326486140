import Container from 'components/Container';
import { Textarea } from 'components/Form';
import MainTitle from 'components/MainTitle';
import { Row } from 'react-flexbox-grid';
import { Field } from 'redux-form';

function Description() {
  return (
    <section id="description" style={{ paddingBottom: '40px' }}>
      <MainTitle
        title="Descrição Privada"
        text="Forneça detalhes internos e informações exclusivas do condomínio/empreendimento."
      />
      <Container>
        <Row>
          <Field
            height={275}
            xs={12}
            labelRender={() => (
              <div className="h-flex h-flex--between h-flex--center-center">
                <label className="FormLabel">
                  Texto sobre o condomínio/empreendimento
                </label>
              </div>
            )}
            name="private_note"
            component={Textarea}
          />
        </Row>
      </Container>
    </section>
  );
}

export default Description;
