import React, { useCallback, useMemo } from 'react';
import { Row } from 'react-flexbox-grid';
import StateField from 'containers/LocationFields/StateField';
import CityField from 'containers/LocationFields/CityField';
import FormBox from 'components/FormBox';
import NeighborhoodsSelect from '../../components/NeighborhoodsSelect';
import Button from 'components/Button';
import { useDispatch } from 'react-redux';
import { openModalAddCity } from 'modules/modal';
import { COUNTRY_ID } from 'constants/constants';
import { Actions as ActionsLocation } from 'containers/LocationFields/module';
import * as locationService from 'services/location';
// import { Container } from './styles';

const MultipleLocationContainer = ({ fields, change }) => {
  const dispatch = useDispatch();

  const total = useMemo(() => {
    return fields.length;
  }, [fields]);

  const isLast = useCallback(
    (index) => {
      return index === total - 1;
    },
    [total]
  );

  const fetchCities = async (stateId, name) => {
    if (!stateId) return false;

    dispatch(ActionsLocation.request('ProfileForm', name));
    const { data } = await locationService.getCities({
      state_id: stateId,
    });

    dispatch(ActionsLocation.receive('ProfileForm', name, data));
  };

  const handleOpenModalAddCity = useCallback(
    (values, index, name) => () => {
      const cityName = `${name}.city_id`;
      const neighborhoodName = `${name}.neighborhood_id`;

      dispatch(
        openModalAddCity({
          initialValues: {
            country_id: COUNTRY_ID,
            state_id: values.state_id,
          },
          onAddCity: async (res) => {
            await fetchCities(res.data.state_id, cityName);

            // Atualiza o item no índice especificado usando splice
            dispatch(change(cityName, res.data.first_neighborhood.city_id));
            dispatch(
              change(neighborhoodName, [res.data.first_neighborhood.id])
            );
          },
        })
      );
    },
    []
  );

  if (!fields) return null;

  return (
    <>
      {fields?.map((location, index, fields) => {
        const values = fields.get(index);
        return (
          <FormBox
            styleHeader={{ width: '100%' }}
            title="Localização"
            actions={
              index > 0 && (
                <Button color="danger" onClick={() => fields.remove(index)}>
                  Remover
                </Button>
              )
            }
          >
            <Row>
              <StateField noCountry xs={3} name={`${location}.state_id`} />
              <CityField
                xs={3}
                name={`${location}.city_id`}
                stateId={values.state_id}
                label={
                  <>
                    Cidade{' '}
                    <span
                      onClick={handleOpenModalAddCity(values, index, location)}
                      className="h-link"
                    >
                      (Cadastrar nova)
                    </span>
                  </>
                }
              />
            </Row>
            <NeighborhoodsSelect
              change={change}
              fields={fields}
              name={location}
              cityId={values.city_id}
              values={values}
            />

            {isLast(index) && (
              <Button
                type="button"
                onClick={() => fields.push({})}
                style={{ marginTop: '15px' }}
              >
                Adicionar outra cidade
              </Button>
            )}
          </FormBox>
        );
      })}
    </>
  );
};

export default MultipleLocationContainer;
