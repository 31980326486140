import React from 'react';
// Components
import ItemNav from '../../ItemNav';
import { useCan } from 'hooks/useCan';
import Ball from 'components/Ball';
import { useStorage } from 'hooks/useStorage';

function MenuFinancial() {
  const { value, set } = useStorage({ key: '@tecimob/FINANCIAL_VISITED' });

  const canShowFinancial = useCan({
    run: 'VIEW_FINANCIAL',
  });

  if (!canShowFinancial) return null;

  return (
    <ItemNav
      active="/financial/"
      to="/financial/dashboard"
      exact
      label={
        <>
          Financeiro{' '}
          {!value ? <Ball className="Menu__ball h-margin-left--5" /> : null}
        </>
      }
      onClick={() => {
        set(true);
      }}
    />
  );
}

export default MenuFinancial;
