import { ModalClose } from 'components/Modal';
import {
  fetchPropertiesCount,
  fetchUsers,
  initialize as initializeModal,
  propertiesCountSelector,
} from 'modules/modalPropertySearch';
import qs from 'qs';
import { useEffect } from 'react';
import Modal from 'react-modal';
import { withRouter } from 'react-router-dom';
import { reduxForm } from 'redux-form';
import AsideFilter from './components/AsideFilter';
import MainFilter from './components/MainFilter';
import { Content, Wrapper } from './styles';

import { addLastSearch } from 'modules/propertySearch';

import { ModalPropertyProvider } from 'containers/ModalRoot/ModalPropertySearch/context';
import { transformFilter } from 'containers/ModalRoot/ModalPropertySearch/helpers';
import { connect, useDispatch } from 'react-redux';
import { compose } from 'redux';
import { useCloseModalWithEsc } from 'hooks/useCloseModalWithEsc';

// const getSearchValues = getFormValues('ModalPropertySearch');

function ModalPropertySearch({
  modalConfig,
  isFetching,
  isOpen,
  modalType,
  handleClose,
  handleSubmit,
  filter,
  initialize,
  isPortals = false,
  redirectTo,
}) {
  // Quando clicar no esc fecha modal
  useCloseModalWithEsc();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchUsers());
  }, [dispatch]);

  const handleUpdateCount = () => {
    if (isPortals) {
      dispatch(fetchPropertiesCount({ isPortals }));
    } else {
      dispatch(fetchPropertiesCount());
    }
  };

  useEffect(() => {
    if (filter) {
      const _filter = transformFilter(filter);

      initialize({ ..._filter, status: 1 });
      handleUpdateCount();
    } else {
      const modalSearch = localStorage.getItem('@tecimob/modalSearch');

      if (modalSearch) {
        initialize({ ...modalSearch, status: 1 });
      }
    }
  }, [filter]);

  useEffect(() => {
    dispatch(initializeModal());
  }, []);

  return (
    <ModalPropertyProvider handleUpdateCount={handleUpdateCount}>
      <Modal
        {...modalConfig}
        isFetching={isFetching}
        isOpen={isOpen}
        contentLabel={modalType}
        onRequestClose={handleClose}
      >
        <Wrapper onSubmit={handleSubmit}>
          <ModalClose handleClose={handleClose} />
          <Content>
            <MainFilter />
            <AsideFilter />
          </Content>
        </Wrapper>
      </Modal>
    </ModalPropertyProvider>
  );
}

const mapStateToProps = (state) => ({
  propertiesCount: propertiesCountSelector(state),
});

export default compose(
  connect(mapStateToProps),
  withRouter,
  reduxForm({
    form: 'ModalPropertySearch',
    initialValues: {
      status: 1,
    },
    onSubmit: (_values, dispatch, props) => {
      const values = { ..._values };

      const count = props.propertiesCount;

      const available_lower_equals = values.available_lower_equals;
      const available_greater_equals = values.available_greater_equals;

      delete values.available_lower_equals;
      delete values.available_greater_equals;

      // Une os campos available_lower_equals e available_greater_equals em season_available_between
      if (available_lower_equals && available_greater_equals) {
        values.season_available_between = `${available_lower_equals}-${available_greater_equals}`;
      }

      const modalSearch = localStorage.getItem('@tecimob/modalSearch');

      if (values.by_state_id || values.by_city_id) {
        localStorage.setItem('@tecimob/modalSearch', {
          ...modalSearch,
          by_state_id: values?.by_state_id || null,
          by_city_id: values?.by_city_id || null,
        });
      }

      const filter = qs.stringify({
        filter: { ...values, count },
        limit: 50,
        offset: 1,
        sort: '-calculated_price',
      });

      dispatch(addLastSearch(filter));

      const redirectUri = props.redirectTo || `/properties/search`;

      props.history.push(`${redirectUri}?${filter}`);

      if (props.onSearch) props.onSearch(values, dispatch, props);

      props.handleClose();
    },
  })
)(ModalPropertySearch);
