import { Financial } from 'features/Financial';
import * as S from './styles';
import Button from 'components/Button';
import { fetchStatusGuard } from '../../../../modules/statusGuard';
import { useDispatch } from 'react-redux';
import imageTrialEnded from './imageTrialEnded.svg';
import { ITEM_TYPES } from 'services/financialv2/contracts';
import { closeModal, openModalContractExtra } from 'modules/modal';
import { usePreview1 } from 'hooks/api/contracts';

export function TrialPeriodEnded() {
  const dispatch = useDispatch();
  const { fetchPreview1 } = usePreview1();

  const handleContract = async () => {
    const { data: preview } = await fetchPreview1({
      item: ITEM_TYPES.EMAIL,
      is_adding: false,
    });

    dispatch(
      openModalContractExtra({
        item: ITEM_TYPES.FINANCIAL,
        preview,
        onSuccess: () => {
          dispatch(fetchStatusGuard());
          dispatch(closeModal());
        },
      })
    );
  };

  return (
    <Financial.Content>
      <S.Box>
        <S.Wrapper>
          <S.BoxLeft>
            <S.Title>Seu teste gratuito chegou ao fim</S.Title>
            <S.Text>
              Esperamos que você tenha aproveitado ao máximo o Módulo Financeiro
              nesses últimos 7 dias. Agora que o teste se encerrou, queremos
              muito que você continue a ter todos os benefícios de um controle
              financeiro mais eficiente.
              <br />
              <br />
              Para manter o acesso ao módulo, basta clicar no botão abaixo e
              confirmar a assinatura de R$ 30,00/mês.
              <br />
              <br />
              Agradecemos por testar o nosso Módulo Financeiro e esperamos
              continuar contribuindo para o sucesso do seu negócio!
              <br />
              <br />
              Caso tenha qualquer dúvida, nossa equipe de suporte está à
              disposição para ajudar no que for preciso
            </S.Text>
            <Button color="success" onClick={handleContract}>
              Contratar
            </Button>
          </S.BoxLeft>
          <S.BoxRight>
            <img src={imageTrialEnded} alt="" />
          </S.BoxRight>
        </S.Wrapper>
      </S.Box>
    </Financial.Content>
  );
}
