import React from 'react';
// Components
import FilterField from 'components/FilterField';
import { SelectMultiple } from 'components/Form';
// Constants
import { NEAR_SEA } from 'constants/options';
// Helpers
import { parseSelect } from 'lib/formHelpers';

const NearSeaField = ({ onChange }) => (
  <FilterField
    multi={false}
    label="Próximo ao mar"
    name="near_sea"
    component={SelectMultiple}
    valueKey="value"
    labelKey="label"
    options={NEAR_SEA}
    parse={parseSelect('value')}
    onChange={onChange}
  />
);

export default NearSeaField;
