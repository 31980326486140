import { Header } from 'features/Financial/components/Header';
import { Financial } from 'features/Financial';
import { TableMoviments } from './components/TableMoviments';
import { useMovimentsList } from 'features/Financial/hooks/usePostings';
import { MetaInformations } from './components/MetaInformations';
import { FilterPostings, selector } from './components/FilterPostings';
import moment, { format } from 'lib/moment';
import { useDispatch, useSelector } from 'react-redux';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Popover from 'components/Popover';
import Button from 'components/Button';
import { MdFilter } from 'react-icons/all';
import { useHistory, useLocation } from 'react-router-dom';
import { useQueryParamCount } from 'hooks/useQueryParamCount';
import Label from 'components/Label';
import { plural } from 'lib/text';
import { MdClose } from 'react-icons/md/index';

export function ListPostings() {
  const dispatch = useDispatch();
  const paramCount = useQueryParamCount();
  const history = useHistory();
  const location = useLocation();

  const [initialValues, setInitialValues] = useState({
    start_at: moment().startOf('month').format(format.date),
    end_at: moment().endOf('month').format(format.date),
    category_type: '',
    situation: '',
    repetition_type: '',
  });

  const { fetchMoviments, isFetchingMoviments, moviments, movimentsMeta } =
    useMovimentsList();

  const date = useSelector((state) => selector(state, 'start_at', 'end_at'));

  const isDateRangeGreaterThanOneMonth = useMemo(() => {
    const start = moment(date?.start_at, format.date);
    const end = moment(date?.end_at, format.date);
    return end.diff(start, 'months', true) > 1;
  }, [date]);

  const handleDateChange = (value) => {
    const startOfMonth = moment(value).startOf('month').format(format.date);
    const endOfMonth = moment(value).endOf('month').format(format.date);

    // Atualizar a URL
    const params = new URLSearchParams(location.search);

    params.set('start_at', startOfMonth);
    params.set('end_at', endOfMonth);

    // Atualiza a url de fato
    history.push({ search: params.toString() });

    // Atualizar o estado local
    setInitialValues((prev) => ({
      ...prev,
      start_at: startOfMonth,
      end_at: endOfMonth,
    }));
  };

  const params = useMemo(() => {
    return new URLSearchParams(location.search);
  }, [location]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);

    const start_at =
      params.get('start_at') || moment().startOf('month').format(format.date);
    const end_at =
      params.get('end_at') || moment().endOf('month').format(format.date);
    const category_type = params.get('category_type') || '';
    const situation = params.get('situation') || '';
    const repetition_type = params.get('repetition_type') || '';

    setInitialValues({
      start_at,
      end_at,
      category_type,
      situation,
      repetition_type,
    });

    fetchMoviments({
      start_at,
      end_at,
      category_type,
      situation,
      repetition_type,
    });
  }, [location]);

  const handleClearFilters = useCallback(() => {
    // Remove os parâmetros da URL
    history.push({ search: '' });

    // Reseta os filtros para os valores iniciais
    setInitialValues({
      start_at: moment().startOf('month').format(format.date),
      end_at: moment().endOf('month').format(format.date),
      category_type: '',
      situation: '',
      repetition_type: '',
    });

    // Refaz a requisição com os filtros limpos
    fetchMoviments({
      start_at: moment().startOf('month').format(format.date),
      end_at: moment().endOf('month').format(format.date),
      category_type: '',
      situation: '',
      repetition_type: '',
    });
  }, [history, fetchMoviments]);

  function getSearchParams(location) {
    const params = new URLSearchParams(location.search);

    return {
      start_at:
        params.get('start_at') || moment().startOf('month').format(format.date),
      end_at:
        params.get('end_at') || moment().endOf('month').format(format.date),
      category_type: params.get('category_type') || '',
      situation: params.get('situation') || '',
      repetition_type: params.get('repetition_type') || '',
    };
  }

  function request() {
    const searchParams = getSearchParams(location);

    fetchMoviments({
      start_at: searchParams.start_at,
      end_at: searchParams.end_at,
      category_type: searchParams.category_type,
      situation: searchParams.situation,
      repetition_type: searchParams.repetition_type,
    });
  }

  // Atualiza a URL ao submeter
  const handleSubmit = useCallback(
    (values) => {
      const params = new URLSearchParams();

      if (values.start_at) {
        params.set('start_at', values.start_at);
      }
      if (values.end_at) {
        params.set('end_at', values.end_at);
      }
      if (values.category_type) {
        params.set('category_type', values.category_type);
      }
      if (values.situation) {
        params.set('situation', values.situation);
      }
      if (values.repetition_type) {
        params.set('repetition_type', values.repetition_type);
      }

      // Atualiza a URL com os novos parâmetros
      history.push({ search: params.toString() });

      // Faz a requisição usando os valores
      fetchMoviments({
        start_at: values.start_at,
        end_at: values.end_at,
        category_type: values.category_type,
        situation: values.situation,
        repetition_type: values.repetition_type,
      });
    },
    [history, fetchMoviments]
  );

  return (
    <>
      <Header.Header>
        <Header.Title>Lançamentos</Header.Title>
        <Header.Actions>
          {paramCount > 2 ? (
            <Label
              color="default"
              onClick={handleClearFilters}
              className="h-margin-right--15"
            >
              ({paramCount - 2}){' '}
              {plural(
                paramCount - 2,
                'Filtro selecionado',
                'Filtros selecionados',
                false
              )}{' '}
              <MdClose />
            </Label>
          ) : null}
          <Popover
            width={265}
            onChange={handleSubmit}
            initialValues={initialValues}
            onAddPosting={request}
            component={FilterPostings}
          >
            <Button
              type="button"
              color="white"
              size="small"
              className="h-margin-right--15"
            >
              <MdFilter className="h-margin-right--5" />
              Filtros
            </Button>
          </Popover>
          <Financial.AddPosting
            onSuccess={() => {
              request();
            }}
          />
        </Header.Actions>
      </Header.Header>
      <Financial.Content isFetching={isFetchingMoviments}>
        {!isDateRangeGreaterThanOneMonth && (
          <Financial.DateSelector
            initialValue={moment(initialValues.start_at, format.date).format(
              'MM-YYYY'
            )}
            type="month"
            onChange={handleDateChange}
            className="h-margin-bottom--15"
          />
        )}
        <TableMoviments data={moviments} />
        <MetaInformations meta={movimentsMeta} />
      </Financial.Content>
    </>
  );
}
