import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
  margin-top: 23px;
`;

export const Card = styled.div`
  display: grid;
  place-items: center;
  width: 44px;
  height: 40px;

  filter: drop-shadow(1px -1px 5px rgba(0, 0, 0, 0.08));
  border: 1px solid ${({ isSelected }) => (isSelected ? '#0063C0' : '#F1F4F9')};
  border-radius: 4px;
  cursor: pointer;
  transition: border-color 0.2s ease, transform 0.2s ease;
  background: ${({ isSelected }) => (isSelected ? '#f0f8ff' : '#fff')};

  .icon {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    font-size: 16px;
    color: #fff;
    background: #31d084;
    display: grid;
    place-items: center;
  }
`;
