// import styles from './styles'

import { Field, reduxForm } from 'redux-form';
import { ModalFooter } from 'components/Modal';
import Button from 'components/Button';
import { Row } from 'react-flexbox-grid';
import { Fields } from 'features/Financial';
import { Input, InputDate } from 'components/Form';
import { useHistory } from 'react-router';
import { useGetAccounts } from '../../../../hooks/useAccounts';
import { useEffect } from 'react';

function FormBillPay({ handleSubmit, handleClose }) {
  const history = useHistory();

  const { accounts, isFetchingAccounts, fetchAccounts } = useGetAccounts({
    type: 1,
  });

  useEffect(() => {
    fetchAccounts({ limit: null });
  }, []);

  if (isFetchingAccounts) return false;

  if (!accounts || accounts.length === 0)
    return (
      <div>
        <p>
          Para dar baixa em uma conta, é necessário ter pelo menos uma conta
          bancária cadastrada.
        </p>
        <Button
          color="success"
          className="h-margin-top--15"
          onClick={() => {
            handleClose();
            history.push('/financial/accounts');
          }}
        >
          Cadastrar conta bancária
        </Button>
      </div>
    );

  return (
    <form onSubmit={handleSubmit}>
      <Row>
        <Fields.Account xs={12} label="Conta" />
      </Row>
      <Row>
        <Field
          xs={6}
          valueFormat
          label="Valor pago"
          name="value"
          component={Input}
          prefix="R$ "
          inputProps={{
            decimalScale: 2,
          }}
          autoComplete="off"
        />
        <Field
          autoComplete={false}
          xs={6}
          label="Data"
          name="billing_date"
          component={InputDate}
        />
      </Row>

      <ModalFooter>
        <Button type="button" color="white" onClick={handleClose}>
          Cancelar
        </Button>
        <span className="h-flex__cell--grow" />
        <Button type="submit" color="success">
          Marcar como recebido
        </Button>
      </ModalFooter>
    </form>
  );
}

export default reduxForm({ form: 'FormBillPay' })(FormBillPay);
