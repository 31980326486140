import MainTitle from 'components/MainTitle';
import Wrapper from 'components/Wrapper';
import { openConfirmation } from 'containers/ModalConfirmation/module';
import {
  openModalAddZone,
  openModalZoneDelete,
  openModalZoneStore,
} from 'modules/modal';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as locationService from 'services/location';
import TableZones from './components/TableZones';
import Button from 'components/Button';

export default function Zones() {
  const [data, setData] = useState([]);
  const [isFetching, setIsFetching] = useState(true);

  const dispatch = useDispatch();

  const fetchZones = useCallback(async () => {
    setIsFetching(true);
    const { data } = await locationService.getZones({
      include: 'city,city.state',
      count: 'properties',
      with_defaults: false,
    });
    setData(data);
    setIsFetching(false);
  }, []);

  const handleEdit =
    ({ id }) =>
    () => {
      openModalZoneStore({
        id,
        onSubmitSuccess: ({ data: currentZone }) => {
          const zones = data.map((zone) => {
            if (currentZone?.id === zone?.id) {
              return {
                ...zone,
                ...currentZone,
              };
            }
            return zone;
          });

          setData(zones);
        },
      })(dispatch);
    };

  const openModalDelete = useCallback(
    (id) => () => {
      dispatch(
        openModalZoneDelete({
          id,
          onSubmitSuccess: () => {
            fetchZones();
          },
        })
      );
    },
    []
  );

  const handleDelete =
    ({ id, properties_count }) =>
    () => {
      if (properties_count > 0) {
        return openModalDelete(id)();
      }

      dispatch(
        openConfirmation({
          title: 'Remover zona',
          text: 'Deseja realmente remover a zona?',
          request: () => locationService.removeZone(id),
          onSuccess: () => {
            // Remove da lista
            const zones = data.filter((zone) => zone.id !== id);

            setData(zones);
          },
        })
      );
    };

  const handleAddZone = () => {
    dispatch(
      openModalAddZone({
        initialValues: {},
        onAddZone: () => {
          fetchZones();
        },
      })
    );
  };

  useEffect(() => {
    fetchZones();
  }, []);

  return (
    <Wrapper.container>
      <MainTitle
        title="Zonas"
        text="Gerencie aqui todas as zonas que você cadastrou no sistema."
      >
        <Button color="secondary" onClick={handleAddZone}>
          Adicionar zona
        </Button>
      </MainTitle>
      <TableZones
        data={data}
        isFetching={isFetching}
        onDelete={handleDelete}
        onEdit={handleEdit}
      />
    </Wrapper.container>
  );
}
