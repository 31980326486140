import React from 'react';
// Components
import FilterField from 'components/FilterField';
import { SelectMultiple } from 'components/Form';
// Constants
import { PROFILE } from 'constants/options';
// Helpers
import { parseSelect } from 'lib/formHelpers';

const ProfileField = ({ onChange }) => (
  <FilterField
    multi={false}
    label="Perfil do imóvel"
    name="profile"
    component={SelectMultiple}
    valueKey="value"
    labelKey="label"
    options={PROFILE}
    parse={parseSelect('value')}
    onChange={onChange}
  />
);

export default ProfileField;
