import React from 'react';
import PropTypes from 'prop-types';
// Components
import IconCard from './IconCard';
import { Wrapper } from './styles';

const IconSelect = ({ input, options, valueKey, iconKey }) => {
  const handleSelect = (value) => {
    input.onChange(value);
  };

  if (!options) return null;

  return (
    <Wrapper>
      {options.map((option) => {
        return (
          <IconCard
            key={`icon-card-${option[valueKey]}`}
            isSelected={input.value === option[valueKey]}
            icon={option[iconKey]}
            onClick={() => handleSelect(option[valueKey])}
          />
        );
      })}
    </Wrapper>
  );
};

IconSelect.propTypes = {
  input: PropTypes.shape({
    value: PropTypes.any,
    onChange: PropTypes.func.isRequired,
  }).isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      valueKey: PropTypes.string,
      iconKey: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
    })
  ).isRequired,
  labelKey: PropTypes.string.isRequired,
  valueKey: PropTypes.string.isRequired,
  iconKey: PropTypes.string.isRequired,
};

export default IconSelect;
