import { Financial } from 'features/Financial';
import { MdCheck } from 'react-icons/md';
import * as S from './styles';
import Button from 'components/Button';
import { askStatusGuard } from '../../../../modules/statusGuard';
import { useDispatch } from 'react-redux';
import { useRef } from 'react';

export function WaitingList() {
  const dispatch = useDispatch();

  const videoRef = useRef();

  const requestFullScreen = () => {
    if (videoRef.current.requestFullscreen) {
      videoRef.current.requestFullscreen();
    } else if (videoRef.current.webkitRequestFullscreen) {
      // Para navegadores baseados no WebKit (Safari)
      videoRef.current.webkitRequestFullscreen();
    } else if (videoRef.current.mozRequestFullScreen) {
      // Para navegadores baseados no Firefox
      videoRef.current.mozRequestFullScreen();
    } else if (videoRef.current.msRequestFullscreen) {
      // Para navegadores baseados no IE
      videoRef.current.msRequestFullscreen();
    }
  };

  const handleVideoClick = () => {
    if (videoRef.current) {
      if (videoRef.current.paused) {
        videoRef.current.play();
        requestFullScreen();
      } else {
        videoRef.current.pause();
      }
    }
  };

  return (
    <Financial.Content>
      <S.Box>
        <S.Wrapper>
          <S.BoxLeft>
            <S.Title>Conheça nosso novo Módulo Financeiro!</S.Title>
            <S.Text>
              Uma ferramenta prática e eficiente para simplificar sua gestão
              financeira. Com ele, você pode:
            </S.Text>
            <S.List>
              <li>
                <S.Ball>
                  <MdCheck />
                </S.Ball>
                Realizar lançamentos financeiros de forma organizada;
              </li>
              <li>
                <S.Ball>
                  <MdCheck />
                </S.Ball>
                Acompanhar o fluxo de caixa com clareza;
              </li>
              <li>
                <S.Ball>
                  <MdCheck />
                </S.Ball>
                Acompanhar saldo de contas bancárias e cartões
              </li>
            </S.List>
            <p>
              Para garantir que todos recebam o suporte adequado, o acesso será
              liberado gradualmente por uma fila de espera.
              <br />
              <br />
              Quando chegar a sua vez, você terá 7 dias de teste gratuito e, ao
              final do período, poderá optar pela contratação com um
              investimento mensal de R$ 30,00.
              <br />
              <br />
              Aproveite esta oportunidade para otimizar sua gestão financeira e
              conquistar resultados ainda melhores!
            </p>
            <Button
              block
              color="success"
              onClick={() => {
                dispatch(askStatusGuard());
              }}
            >
              Quero entrar na fila de espera
            </Button>
          </S.BoxLeft>
          <S.BoxRight>
            <S.WrapVideo>
              <video
                ref={videoRef}
                muted={false}
                width="100%"
                poster="/video-financeiro.png"
                onClick={handleVideoClick}
              >
                <source src="/video-financeiro.mp4" type="video/mp4" />
                Seu navegador não suporta o elemento de vídeo.
              </video>
            </S.WrapVideo>
          </S.BoxRight>
        </S.Wrapper>
      </S.Box>
    </Financial.Content>
  );
}
