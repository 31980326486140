import React from 'react';
import { useWindowSize } from 'containers/ModalRoot/ModalPaymentSuccess/hooks/useWindowSize';
import { Wrapper } from './styles';
import Box from './components/Box';
import chrome from './assets/chrome-web-store.png';
import appStore from './assets/app-store.png';
import googlePlay from './assets/google-play.png';
import Poplink from 'components/WizardVideo/components/Poplink';
import { useChromeExtension } from 'pages/NewDashboard/components/Advertisements/hooks/useChromeExtension';
import { useCanShowMobileBanner } from 'pages/NewDashboard/components/Advertisements/hooks/useCanShowMobileBanner';

function Advertisements() {
  const { canShowBanner, handleOnClose } = useChromeExtension();
  const { canShowMobileBanner, handleOnClose: handleCloseMobile } =
    useCanShowMobileBanner();
  const windowSize = useWindowSize();

  if (windowSize.width < 1366) return false;

  return (
    <Wrapper>
      {canShowBanner && (
        <Box
          title="Seu CRM dentro do seu WhatsApp Web"
          text="Seu CRM integrado ao WhatsApp Web para um atendimento eficaz aos seus clientes!"
          handleClose={handleOnClose}
        >
          <a
            href="https://chrome.google.com/webstore/detail/tecimob/agmpfkakhlepfjdmfddjeophdnidpjhm?hl=pt-BR"
            target="_blank"
          >
            <img src={chrome} alt="" />
          </a>
        </Box>
      )}
      {canShowMobileBanner && (
        <Box
          title="Transforme sua experiência agora!"
          text="Baixe o aplicativo e receba notificações em tempo real para atender seus clientes de qualquer lugar!"
          handleClose={handleCloseMobile}
        >
          <div className="h-flex">
            <Poplink
              pin="left"
              link="https://apps.apple.com/us/app/tecimob/id1486403299"
            >
              <a
                target="_blank"
                href="https://apps.apple.com/us/app/tecimob/id1486403299"
                rel="noopener noreferrer"
                className="h-margin-right--5"
              >
                <img src={appStore} alt="Apple Store" />
              </a>
            </Poplink>

            <Poplink
              pin="left"
              link="https://play.google.com/store/apps/details?id=com.tecimob_app"
            >
              <a
                target="_blank"
                href="https://play.google.com/store/apps/details?id=com.tecimob_app"
                rel="noopener noreferrer"
                className="h-margin-right--5"
              >
                <img src={googlePlay} alt="Google play" />
              </a>
            </Poplink>
          </div>
        </Box>
      )}
    </Wrapper>
  );
}

export default Advertisements;
