import React from 'react';
import PropTypes from 'prop-types';
import { Card } from './styles';

const IconCard = ({ isSelected, icon: Icon, onClick }) => {
  return (
    <Card
      isSelected={isSelected}
      onClick={onClick}
      role="button"
      tabIndex={0}
      onKeyDown={(e) => {
        if (e.key === 'Enter' || e.key === ' ') {
          e.preventDefault();
          onClick();
        }
      }}
    >
      <div className="icon">{Icon && <Icon />}</div>
    </Card>
  );
};

IconCard.propTypes = {
  isSelected: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  icon: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
  onClick: PropTypes.func.isRequired,
};

export default React.memo(IconCard);
