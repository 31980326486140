import { Financial } from 'features/Financial';
import * as S from './styles';
import { MdAccessTime } from 'react-icons/md';

export function AccessAvailableSoon() {
  return (
    <Financial.Content>
      <S.Box>
        <S.Wrapper>
          <S.BoxLeft>
            <S.Title>Você está na fila de espera!</S.Title>
            <S.Text>
              Sua inscrição foi realizada com sucesso. Você agora faz parte da
              nossa fila de espera para teste do Módulo Financeiro.
              <br />
              <br />
              Em breve, iremos analisar a disponibilidade e liberar o acesso
              para você iniciar o teste gratuito de 7 dias. Fique de olho,
              avisaremos por notificação do sistema e também por e-mail assim
              que o módulo estiver pronto para uso na sua conta.
              <br />
              <br />
              Enquanto isso, se tiver alguma dúvida, consulte nossos artigos de
              ajuda ou entre em contato com o suporte.
              <br />
              <br />
              Obrigado pela paciência e pelo seu interesse!
            </S.Text>
          </S.BoxLeft>
          <S.BoxRight>
            <MdAccessTime fill="#D3DCEB" />
          </S.BoxRight>
        </S.Wrapper>
      </S.Box>
    </Financial.Content>
  );
}
