import Container from 'components/Container';
import { Textarea } from 'components/Form';
import MainTitle from 'components/MainTitle';
import { Row } from 'react-flexbox-grid';
import { Field } from 'redux-form';

function Description() {
  return (
    <section id="description">
      <MainTitle
        title="Descrição pública"
        text="Descreva os pontos fortes do condomínio/empreendimento"
      />
      <Container>
        <Row>
          <Field
            height={275}
            xs={12}
            labelRender={() => (
              <div className="h-flex h-flex--between h-flex--center-center">
                <label className="FormLabel">
                  Texto sobre o condomínio/empreendimento
                </label>
              </div>
            )}
            name="description"
            component={Textarea}
          />
        </Row>
      </Container>
    </section>
  );
}

export default Description;
